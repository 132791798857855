// Grid
.product-item-row {
	display: flex;
	flex-wrap: wrap;
	&:before {
		content: normal;
	}
}
.product-item-column {
	display: flex;
	@extend .gutter-bottom-margin;
	@include breakpoint(small down) {
		margin-bottom: $global-margin !important;
		&:last-child {
			margin-bottom: 0 !important;
		}
	}
}
// Product Item
.product-item {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: space-between;
	justify-content: center;
	position: relative;
	overflow: hidden;
	width: 100%;
	border: rem-calc(1) solid get-neutral-color(90%);
	background-color: get-color(white);
	padding: $global-padding;
	border-radius: $global-radius;
	&:hover {
		transform: translatey(-4px);
		border-color: get-color(medium-grey);
	}
	@include breakpoint(small down) {
		padding: 0;
	}
	&-image {
		display: block;
		position: relative;
		overflow: hidden;
		width: 100%;
		background-color: get-color(x-light-grey);
		border-radius: $global-radius;
		padding: $global-padding * 3;
		margin-bottom: $global-margin;
		img {
			mix-blend-mode: multiply;
		}
		@include breakpoint(medium down) {
			img {
				display: block;
				max-width: rem-calc(200);
				margin: auto;
			}
		}
		@include breakpoint(small down) {
			img {
				max-width: rem-calc(150);
			}
		}
	}
	&-offer {
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: $global-position;
		left: $global-position;
		z-index: 10;
		background-color: get-color(primary);
		color: get-color(white);
		border-radius: $global-radius;
		font-size: rem-calc(15);
		line-height: $global-lineheight / 1.2;
		font-weight: $global-weight-bold;
		letter-spacing: rem-calc(1);
		text-transform: uppercase;
		width: rem-calc(50);
		height: rem-calc(50);
	}
	&-text {
		display: block;
		position: relative;
		text-align: center;
	}
	&-heading {
		@extend h4;
		font-weight: $global-weight-normal;
		max-width: rem-calc(180);
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
		@include breakpoint(small down) {
			margin-bottom: $global-margin;
		}
	}
	&-price {
		@extend h3;
		color: get-color(primary);
		margin-top: $global-margin;
		@include breakpoint(small down) {
			margin-top: $global-margin / 1.5;
		}
		small {
			color: get-color(black);
		}
	}
	&-sale {
		@extend h5;
		margin: 0;
		font-weight: $global-weight-normal;
		@include breakpoint(small down) {
			margin-bottom: $global-margin;
		}
		span {
			margin-left: $global-margin / 5;
			margin-right: $global-margin / 5;
		}
	}
}
.price-inc-vat {
	font-size: 80%;
}

// ===============================
//
// Base
//
// ===============================

	//
	// Body
	//

		body {
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			text-rendering: optimizeLegibility;
		}

		::selection {
			color: $selection-color;
			background-color: $selection-background-color;
		}

	//
	// Links
	//

		a {
			transition: $global-transition;
		}

	//
	// Typography
	//

		p {
			font-weight: $global-weight-book;
			letter-spacing: rem-calc(0.5);
			line-height: $global-lineheight;
			color: get-color(body);
		}

		h1, h2, h3, h4, h5, h6,
		.h1, .h2, .h3, .h4, .h5, .h6 {
			letter-spacing: rem-calc(1);
			text-transform: uppercase;
			&.-strikethrough {
				text-decoration: line-through
			}
		}

		.body-copy {
			ol, ul {
				@extend p;
				margin-left: $global-margin;
			}
			&.-large {
				p {
					font-size: rem-calc(18);
					@include breakpoint(large down) {
						font-size: rem-calc(16);
					}
				}
			}
			h1, h2, h3, h4, h5, h6 {
				margin-bottom: $global-margin / 2;
			}
			p {
				font-size: rem-calc(18);
				@include breakpoint(large down) {
					font-size: rem-calc(16);
				}
				margin-bottom: $global-margin * 1.5;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			strong {
				color: get-color(black);
			}
			a {
				color: get-color(black);
				text-decoration: underline;
				&:hover {
					opacity: 0.6;
				}
			}
		}

// ===============================
//
// Form
//
// ===============================
//
// Labels
//
label {
	display: inline-block;
	vertical-align: top;
	font-weight: 500;
	margin-bottom: rem-calc(6);
	font-weight: $global-weight-normal;
	letter-spacing: rem-calc(0.5);
	font-size: rem-calc(16);
	color: get-color(black);
}

label[for] {
	cursor: pointer;
}
//
// Placeholder
//
::placeholder {
	color: rgba($body-font-color, 0.3);
}
//
// Fields
//
// Inputs, Select, Textarea
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
select,
textarea {
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	outline: none;
	appearance: none;
	margin: 0;
	padding: $global-padding;
	border: rem-calc(1) solid get-neutral-color(80%);
	border-radius: $global-radius;
	background-color: get-color(white);
	color: $body-font-color;
	line-height: $global-lineheight;
	height: auto;
	transition: $global-transition;
	box-shadow: none;
	box-sizing: border-box;
	letter-spacing: rem-calc(0.5);
	@include breakpoint(medium down) {
		padding: $global-padding / 1.5;
	}

	&:active,
	&:hover {
		border-color: get-neutral-color(65%);
	}

	&:focus {
		border-color: get-color(primary);
	}
}
// Checkbox, Radio
input[type="checkbox"],
input[type="radio"],
 {
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	padding: 0;
	height: auto;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
	vertical-align: middle;
	margin-bottom: 0;
}

textarea {
	height: rem-calc(200);
}

// Select
.field-select {
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	position: relative;
	&:after {
		content: "\f0d7";
		@extend .fas;
		position: absolute;
		top: 48%;
		right: $global-padding * 1.2;
		font-size: rem-calc(12);
		transform: translateY(-50%);
		pointer-events: none;
	}
}
//
// Containers
//
.field-container {
	margin-bottom: $global-margin * 1.5;
	@include breakpoint(medium down) {
		margin-bottom: $global-margin;
	}
	&:last-of-type {
		margin-bottom: 0;
	}
}
//
// Grid
//
.field-grid {
	display: flex;
	flex-wrap: wrap;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between;
	.field-container {
		float: left;
		position: relative;
		&:nth-child(odd) {
			width: calc(50% - #{$global-margin * 1.5});
			@include breakpoint(medium down) {
				width: 100%;
			}
		}
		&:nth-child(even) {
			width: 50%;
			@include breakpoint(medium down) {
				width: 100%;
			}
		}
		&.type-size-full {
			width: 100%;
			margin-right: 0;
		}
		&:last-child,
		&:nth-last-child(-n+2):not(.type-size-full) {
			margin-bottom: 0;
			@include breakpoint(medium down) {
				margin-bottom: $global-margin;
			}
		}
		&:last-child {
			@include breakpoint(medium down) {
				margin-bottom: 0 !important
			}
		}
	}
	.validate-alert {
		position: absolute;
		font-weight: $global-weight-bold;
		text-transform: uppercase;
		font-size: rem-calc(12);
		bottom: rem-calc(20);
		right: rem-calc(20);
		pointer-events: none;
		z-index: 3;
		width: rem-calc(18);
		height: rem-calc(18);
		border-radius: 100%;
		color: get-color(black);
		text-align: center;
		padding-top: rem-calc(4);
		background-color: get-color(secondary);
		@include breakpoint(medium down) {
			bottom: rem-calc(13);
			right: rem-calc(13);
		}
	}
	.validate-summary {
		display: none;
	}
}
//
// Search
//
.fields-search {
	display: flex;
	input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]) {
		width: rem-calc(250);
		border: rem-calc(1) solid get-neutral-color(80%);
		border-right: none;
		border-top-right-radius: rem-calc(0);
		border-bottom-right-radius: rem-calc(0);
		padding: $global-padding / 2.5 $global-padding / 1.5;
	}
	button {
		border: rem-calc(1) solid get-neutral-color(80%);
		border-left: none;
		border-top-left-radius: rem-calc(0);
		border-bottom-left-radius: rem-calc(0);
	}
}
//
// Mailing List
//
.fields-mailing-list {
	display: flex;
	position: relative;
	::placeholder {
		color: rgba(get-color(white), 0.3);
	}
	input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]) {
		width: rem-calc(330);
		border: rem-calc(1) solid get-neutral-color(20%);
		background-color: transparent;
		border-right: none;
		border-top-right-radius: rem-calc(0);
		border-bottom-right-radius: rem-calc(0);
		color: get-color(white);
		padding: $global-padding;
		@include breakpoint(large down) {
			width: rem-calc(250);
		}
		@include breakpoint(medium down) {
			width: rem-calc(300);
		}
	}
	button {
		border: none;
		border-left: none;
		border-top-left-radius: rem-calc(0);
		border-bottom-left-radius: rem-calc(0);
	}
	.validate-summary {
		display: none;
	}
	.validate-alert {
		position: absolute;
		top: 50%;
		transform: translatey(-30%);
		left: rem-calc(-20);
		color: get-color(secondary);
	}
}

// ===============================
//
// Icon
//
// ===============================

	//
	// Base
	//
		
		.icon {
			@include svg(transtion, $global-transition);
			@include svg-fill($body-font-color);			
			position: relative;
			display: inline-block;
			vertical-align: middle;
			svg,
			img {
				width: 100%;
				max-width: 100%;
			}
		}
		
	//
	// Sizes
	//
	
		@include icon-sizes();
// Sidebar
.sidebar-toggle {
	display: none;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	border: rem-calc(1) solid get-neutral-color(90%);
	padding: $global-padding / 1.2 $global-padding;
	border-radius: $global-radius;
	margin-bottom: 0;
	i {
		position: relative;
		font-size: rem-calc(12);
		color: get-color(black);
	}
	span {
		@extend h6;
		margin: 0;
	}
	&.-active {
		margin-bottom: $global-margin;
		i {
			transform: rotate(180deg);
		}
	}
	@include breakpoint(medium down) {
		display: flex;
	}
}
.sidebar-container {
	@include breakpoint(medium down) {
		display: block;
		overflow: hidden;
		display: none;
		&.-active {
			display: block;
		}
	}
}
.sidebar {
	display: block;
	position: relative;
	border: rem-calc(1) solid get-neutral-color(90%);
	margin-bottom: $global-margin * 1.5;
	border-radius: $global-radius;
	overflow: hidden;
	&:last-child {
		margin-bottom: 0;
	}
	&-heading {
		display: block;
		position: relative;
		padding: $global-padding $global-padding * 1.5;
		background-color: get-color(x-light-grey);
		@extend h4;
		margin: 0;
		border-top: rem-calc(1) solid get-neutral-color(90%);
		border-bottom: rem-calc(1) solid get-neutral-color(90%);
		&:first-child {
			border-top: 0;
		}
	}
	&-group {
		display: block;
		position: relative;
		&.-filter {
			padding: $global-padding * 1.5;
		}
		&.-text {
			padding: $global-padding * 1.5;
			border-bottom: rem-calc(1) solid get-neutral-color(90%);
			&:last-child {
				border: none;
			}
		}
	}
	&-filter-item {
		display: flex;
		align-items: center;
		position: relative;
		margin-bottom: $global-margin;
		cursor: pointer;
		transition: $global-transition;
		&:last-child {
			margin-bottom: 0;
		}
		i {
			display: inline-block;
			width: rem-calc(20);
			height: rem-calc(20);
			border-radius: 100%;
			background-color: get-color(light-grey);
			border: rem-calc(1) solid get-colour(medium-grey);
			margin-right: $global-margin / 2;
			transition: $global-transition;
		}
		input {
			display: none;
		}
		span {
			font-weight: $global-weight-book;
			letter-spacing: rem-calc(0.5);
			font-size: rem-calc(16);
			margin: 0;
			transition: $global-transition;
		}
		&:hover {
			i {
				transform: scale(1.2);
			}
			span {
				transform: translatex(5px);
			}
		}
		&.-active {
			i {
				transform: scale(0.5);
				background-color: get-color(black);
			}
			span {
				transform: translatex(10px);
			}
		}
	}
	&-link-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		cursor: pointer;
		transition: $global-transition;
		padding: $global-padding;
		border-bottom: rem-calc(1) solid get-neutral-color(90%);
		color: get-color(black);
		span {
			font-weight: $global-weight-book;
			letter-spacing: rem-calc(0.5);
			font-size: rem-calc(14);
			margin: 0;
			transition: $global-transition;
		}
		i {
			transition: $global-transition;
		}
		&:last-child {
			border-bottom: 0;
		}
		&.-active {
			background-color: get-color(secondary);
		}
		&:hover {
			color: get-color(black);
			i {
				transform: scale(1.2);
			}
			span {
				transform: translatex(5px);
			}
		}
	}
	&-location-item {
		display: flex;
		justify-content: space-between;
		position: relative;
		cursor: pointer;
		transition: $global-transition;
		padding: $global-padding * 1.5;
		border-bottom: rem-calc(1) solid get-neutral-color(90%);
		&-icon {
			width: rem-calc(40);
			margin-right: $global-margin * 2;
			flex-shrink: 0;
			i {
				width: rem-calc(40);
				position: relative;
				font-size: rem-calc(40);
				color: get-color(secondary);
				flex-shrink: 0;
				text-align: center;
				span {
					width: rem-calc(20);
					height: rem-calc(20);
					text-align: center;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: get-color(black);
					font-family: $font-family-sans-serif;
					font-size: rem-calc(13);
				}
			}
		}
		&-text {
			flex: 1;
			@extend .body-copy;
			@extend .-large;
		}
		&-heading {
			@extend h3;
			display: flex;
			align-items: flex-end;
			margin-bottom: $global-margin / 2;
			span {
				margin-right: $global-margin;
			}
			a {
				font-weight: $global-weight-book;
				color: get-color(body);
				@extend p;
				text-decoration: underline;
				text-transform: none;
				margin: 0;
			}
		}
		&:last-child {
			border-bottom: 0;
		}
	}
}

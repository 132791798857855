// Hero
.hero-slideshow {
	display: block;
	position: relative;
	border-radius: $global-radius;
	&-content {
		display: flex;
		align-items: center;
		background-color: get-color(x-light-grey)
	}
	&-slide {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: space-between;
		border: rem-calc(1) solid get-color(light-grey);
		border-radius: $global-radius;
		width: 100%;
		min-height: rem-calc(500);
		padding: 0 $global-padding * 5;
		@include breakpoint(medium down) {
			min-height: rem-calc(400);
		}
		@include breakpoint(small down) {
			//display: block;
			flex-direction: column;
			text-align: center;
			min-height: rem-calc(300);
			padding: 0;
			justify-content: center;
		}
	}
	&-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		padding: $global-padding;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
			border-radius: $global-radius;
			overflow: hidden;
			@include breakpoint(small down) {
				opacity: 0.4;
			}
		}
		@include breakpoint(small down) {
			padding: 0;
			border-radius: $global-radius;
			background-color: get-color(black);
		}
	}
	&-heading {
		display: inline-block;
		position: relative;
		z-index: 2;
		font-size: rem-calc(25);
		letter-spacing: rem-calc(1);
		color: get-color(black);
		font-weight: $global-weight-bold;
		text-transform: uppercase;
		text-align: center;
		&.-light {
			color: get-color(medium-grey);
			span {
				color: get-color(white);
			}
			@include breakpoint(small down) {
				color: get-neutral-color(80%);
			}
		}
		span {
			display: block;
			margin-bottom: $global-margin;
			color: get-color(primary);
			font-size: rem-calc(120);
			line-height: $global-lineheight / 1.4;
			@include breakpoint(large down) {
				font-size: rem-calc(85);
			}
			@include breakpoint(medium down) {
				font-size: rem-calc(65);
			}
			@include breakpoint(small down) {
				font-size: rem-calc(45);
			}
		}
		@include breakpoint(large down) {
			font-size: rem-calc(20);
		}
		@include breakpoint(medium down) {
			font-size: rem-calc(16);
		}
		@include breakpoint(small down) {
			color: get-neutral-color(80%);
			span {
				color: get-color(white);
			}
		}
	}
	&-badge {
		display: inline-block;
		position: relative;
		z-index: 2;
		width: rem-calc(170);
		height: rem-calc(170);
		border-radius: 100%;
		background-color: get-color(primary);
		@include breakpoint(large down) {
			width: rem-calc(140);
			height: rem-calc(140);
		}
		@include breakpoint(small down) {
			//display: none;
			margin-top: $global-margin * 2.5;
			background-color: none;
			width: auto;
			height: auto;
		}
		&.-light {
			background-color: get-color(white);
			color: get-color(medium-grey);
			.hero-slideshow {
				&-badge-text {
					color: get-color(primary);
				}
			}
		}
	}
	&-badge-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		color: get-color(white);
		font-weight: $global-weight-bold;
		text-transform: uppercase;
		letter-spacing: rem-calc(1);
		@include breakpoint(small down) {
			font-size: rem-calc(0);
		}
	}
	&-badge-price {
		display: flex;
		align-items: flex-start;
		font-size: rem-calc(50);
		letter-spacing: rem-calc(1);
		@include breakpoint(large down) {
			font-size: rem-calc(35);
		}
		@include breakpoint(small down) {
			font-size: rem-calc(35);
		}
		span {
			position: relative;
			transform: translatey(#{rem-calc(8)});
			font-size: rem-calc(25);
			@include breakpoint(large down) {
				font-size: rem-calc(18);
			}
		}
	}
}

.sticky-button {
	position: fixed;
	display: block;
	z-index: 100;
	transform-origin: 100% 100%;
	top: 37%;
	left: auto;
	right: 0;
	transform: rotate(270deg) translate(0,0) !important;
	span {
		display: inline-block;
		position: relative;
		background-color: get-color(white);
		border-radius: rem-calc(5);
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		padding: $global-padding / 2 $global-padding;
		padding-bottom: $global-padding * 1.2;
		font-weight: $global-weight-normal;
		text-transform: uppercase;
		color: get-color(black);
		box-shadow: 0 0 rem-calc(20) 0 rgba(0,0,0,0.2);
		transition: $global-transition;
		transform: translatey(#{rem-calc(5z)});
		border-bottom: rem-calc(10) solid get-color(secondary);
	}
	&:hover {
		span {
			transform: translatey(#{rem-calc(0)});
		}
	}
	@include breakpoint(small down) {
		display: none;
	}
}

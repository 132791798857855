// Section
.section {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	position: relative;
}
// Section Container
.section-container {
	position: relative;
	width: 100%;
	height: 100%;
	max-width: $global-width;
	padding: {
		left: 10vw;
		right: 10vw;
	};
	@include breakpoint(xlarge down) {
		padding: {
			left: 5vw;
			right: 5vw;
		};
	}
	@include breakpoint(large down) {
		padding: {
			left: 5vw;
			right: 5vw;
		};
	}
	@include breakpoint(small down) {
		padding: {
			left: 7vw;
			right: 7vw;
		};
	}
	@media (min-width: $global-width) {
		margin: {
			left: auto;
			right: auto;
		}
		padding: {
			left: 5vw;
			right: 5vw;
		}
	}
}

.banner {
	display: flex;
	align-items: center;
	justify-content: center;
	@include breakpoint(small down) {
		flex-wrap: wrap;
	}
	&__heading {
		@include breakpoint(small down) {
			width: 100%;
		}
	}
	&__button {
		display: inline-block;
		margin-left: rem-calc(10);
		@include breakpoint(small down) {
			width: 100%;
			display: block;
			margin-top: rem-calc(5);
			margin-left: 0;
		}
	}
}

// Tab Content
.tab-content {
	display: block;
	position: relative;
	overflow: hidden;
	border: rem-calc(1) solid get-neutral-color(90%);
	&-nav {
		display: flex;
		@include breakpoint(small down) {
			flex-wrap: wrap;
		}
	}
	&-nav-item {
		flex: 1;
		text-align: center;
		padding: $global-padding * 1.5;
		font-size: rem-calc(16);
		letter-spacing: rem-calc(0.5);
		color: get-color(medium-grey);
		margin: 0;
		border-bottom: rem-calc(1) solid get-neutral-color(90%);
		border-left: rem-calc(1) solid transparent;
		border-right: rem-calc(1) solid transparent;
		background-color: get-color(x-light-grey);
		@include breakpoint(large down) {
			padding: $global-padding $global-padding * 1.5;
		}
		@include breakpoint(small down) {
			flex: none;
			width: 100%;
			padding: $global-padding $global-padding * 1.5;
			text-align: left;
		}
		&.-active {
			background-color: transparent;
			border-bottom: rem-calc(1) solid transparent;
			border-left: rem-calc(1) solid get-neutral-color(90%);
			border-right: rem-calc(1) solid get-neutral-color(90%);
			@include breakpoint(small down) {
				border-left-color: transparent;
				border-right-color: transparent;
				border-bottom: rem-calc(1) solid get-neutral-color(90%);
			}
			&:first-child {
				border-left-color: transparent;
			}
			&:last-child {
				border-right-color: transparent;
			}
		}
	}
	&-item {
		display: none;
		position: relative;
		padding: $global-padding * 3;
		@include breakpoint(small down) {
			padding: $global-padding * 1.5;
		}
		&.-active {
			display: block;
		}
	}
	&-item-table {
		width: 100%;
		border-collapse: collapse;
		border: rem-calc(1) solid get-neutral-color(90%);
		tr {
			background-color: get-color(x-light-grey);
			td {
				padding: $global-padding;
				&:first-child {
					width: 30%;
					font-weight: $global-weight-bold;
					color: get-color(black);
				}
			}
			&:nth-child(even) {
				background-color:  get-color(light-grey);
			}
		}
	}
	&-item-video {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;
	}
	&-item-video iframe,
	&-item-video object,
	&-item-video embed { 
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Hero
.hero-banner {
	display: flex;
	position: relative;
	background-color: get-color(x-light-grey);
	border-radius: $global-radius;
	overflow: hidden;
	@include breakpoint(small down) {
		margin-bottom: $global-margin * 1.5;
	}
	&-text {
		display: flex;
		align-items: center;
		@extend .medium-12;
		@extend .medium-offset-0;
		@extend .large-5;
		@extend .large-offset-1;
		padding: $global-padding * 2 0;
		@include breakpoint(medium down) {
			order: 2;
			padding: $global-padding * 2.5;
		}
		@include breakpoint(small down) {
			padding: $global-padding * 1.5;
		}
	}
	&-image {
		@extend .medium-12;
		@extend .medium-offset-0;
		@extend .large-4;
		@extend .large-offset-2;
		@include breakpoint(medium down) {
			order: 1;
			height: rem-calc(300);
		}
		@include breakpoint(small down) {
			height: rem-calc(200);
		}
		img {
			object-fit: cover;
			object-position: center center;
			width: 100%;
			height: 100%;
		}
	}
	@include breakpoint(medium down) {
		flex-wrap: wrap;
	}
}

// Footer
.site-footer {
	background-color: get-neutral-color(7%);
	padding-top: $global-padding * 6;
	padding-bottom: $global-padding * 6;
	@include breakpoint(medium down) {
		padding-top: $global-padding * 3;
		padding-bottom: $global-padding * 3;
	}
	@include breakpoint(small down) {
		padding-top: $global-padding * 2;
		padding-bottom: $global-padding * 2;
	}
	&.-bottom {
		border-top: rem-calc(1) solid get-neutral-color(20%);
		background-color: get-neutral-color(1%);
		padding-top: $global-padding * 2;
		padding-bottom: $global-padding * 2;
	}
	&-heading {
		@extend h5;
		color: get-color(white);
		@include breakpoint(small down) {
			margin-bottom: $global-margin / 2;
		}
	}
	&-text {
		p {
			color: get-color(medium-grey);
			font-size: rem-calc(16);
		}
		strong {
			color: get-color(white);
			text-decoration: underline;
		}
	}
	&-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		&:before {
			content: normal;
		}
		&.-vertical-align {
			align-items: center;
		}
	}
	&-column {
		flex: 2;
		@include breakpoint(large down) {
			flex: 3;
		}
		@include breakpoint(small down) {
			flex: none;
			width: 100%;
			margin-bottom: $global-margin * 2;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&:first-child {
			flex: 5;
			padding-right: $global-padding * 4;
			@include breakpoint(medium down) {
				flex: none;
				width: 100%;
				padding: 0;
				margin-bottom: $global-margin * 3;
			}
		}
	}
	&-social {
		display: flex;
		flex-wrap: wrap;
		margin-top: $global-margin * 1.5;
		a {
			width: rem-calc(45);
			height: rem-calc(45);
			border-radius: 100%;
			background-color: get-neutral-color(20%);
			color: get-color(medium-grey);
			margin-right: $global-margin / 1.5;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: rem-calc(14);
			&:hover {
				background-color: get-color(secondary);
				color: get-color(black);
				transform: scale(0.9);
			}
		}
	}
	&-copyright {
		@include breakpoint(small down) {
			display: block;
			width: 100%;
			margin-bottom: $global-margin * 2;
		}
		p {
			color: get-color(medium-grey);
			margin: 0;
			line-height: $global-lineheight * 1.2;
		}
		a {
			color: get-color(white);
			text-transform: uppercase;
			font-weight: $global-weight-normal;
			&:hover {
				opacity: 0.6
			}
		}
	}
	&-navigation {
		ul {
			display: block;
			margin: 0;
			padding: 0;
			text-align: center;
			list-style: none;
			@include breakpoint(small down) {
				text-align: left;
			}
		}
		li {
			display: inline-block;
			margin-left: $global-margin / 2;
			margin-right: $global-margin / 2;
			margin-bottom: $global-margin / 2;
			@include breakpoint(small down) {
				margin-left: 0;
				margin-right: $global-margin * 1.5;
				margin-bottom: $global-margin / 1.5;
			}
		}
		a {
			display: block;
			position: relative;
			color: get-color(white);
			letter-spacing: rem-calc(0.5);
			font-size: rem-calc(15);
			text-transform: uppercase;
			span {
				display: block;
				position: relative;
				transition: $global-transition;
			}
			&:before {
				content: '';
				width: 100%;
				height: rem-calc(4);
				position: absolute;
				bottom: rem-calc(-10);
				left: 0;
				background-color: get-color(secondary);
				transition: $global-transition;
				transform: scaley(0);
				transition-delay: 0.1s;
			}
			&:hover {
				&:before {
					transform: scaley(1);
				}
				span {
					transform: translatey(-3px);
				}
			}
		}
	}
	&-button {
		color: get-color(secondary);
		font-size: rem-calc(13);
		letter-spacing: rem-calc(0.5);
		display: flex;
		align-items: center;
		text-transform: uppercase;
		i {
			position: relative;
			transition: $global-transition;
			margin-right: $global-margin / 2;
		}
		span {
			display: inline-block;
			position: relative;
			transition: $global-transition;
			transition-delay: 0.1s;
		}
		&:hover {
			> span {
				transform: translatex(2px);
			}
			i {
				transform: scale(1.2);
			}
		}
	}
}

.breadcrumbs {
	display: block;
	padding: $global-padding;
	background-color: get-color(light-grey);
	a {
		display: inline-block;
		position: relative;
		margin-right: $global-margin * 2;
		letter-spacing: rem-calc(0.5);
		font-size: rem-calc(14);
		color: get-color(medium-grey);
		&:before {
			content: '\f054';
			@extend .fas;
			position: absolute;
			top: rem-calc(6);
			right: -$global-margin * 1.2;
			font-size: rem-calc(7);
		}
		&:last-child {
			&:before {
				display: none;
			}
		}
		&:hover {
			color: get-color(black);
			&:before {
				color: get-color(medium-grey);
			}
		}
		&.is-active {
			color: get-color(black);
		}
	}
	@include breakpoint(small down) {
		display: none;
	}
}

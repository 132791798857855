// Image Grid
.image-grid {
	display: block;
	position: relative;
	&-item {
		display: block;
		position: relative;
		border-radius: $global-radius;
		overflow: hidden;
		transition: $global-transition;
		&:hover {
			transform: scale(0.98);
		}
	}
}

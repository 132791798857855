.slideshow-container {
	position: relative;
	// overflow: hidden;
	display: block;
	width: 100%;
	height: auto;
}
.slideshow {
	display: block;
	position: relative;
	opacity: 0.0;
	transform: translatey(10px);
	transition: $global-transition;
	transition-delay: 1s;
	&.owl-loaded {
		height: auto;
		opacity: 1.0;
		transform: translatey(0px);
	}
	&-loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 5;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: $global-transition;
		opacity: 0.0;
		pointer-events: none;
		i {
			animation: rotate 1.4s get-easing(in-out-circ) infinite;
		}
		&.is-active {
			opacity: 1.0;
		}
	}
	// Custom Pagination
	&-pagination {
		display: block;
		position: relative;
		ul {
			list-style: none;
			display: block;
			position: relative;
			margin: 0;
			padding: 0;
		}
		li {
			@extend .owl-dot;
		}
	}
	// Types
	&[data-slideshow-type="product-item-carousel"] {
		.owl-stage {
			display: flex;
			flex-wrap: wrap;
		}
		.owl-item {
			display: flex;
			height: auto !important;
		}
	}
}
// Controls
.owl-carousel {
	.owl-nav {
		display: block;
		width: 100%;
		position: absolute;
		top: calc(50% - (#{rem-calc(47)} / 2));
		z-index: 10;
		transition: $global-transition;
		@include breakpoint(medium down) {
			position: relative;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: $global-margin;
		}
		button.owl-prev,
		button.owl-next {
			display: block;
			position: absolute;
			top: 50%;
			left: rem-calc(-60);
			width: rem-calc(47);
			height: rem-calc(47);
			border-radius: 100%;
			background-color: get-color(secondary);
			@include breakpoint(large down) {
				left: rem-calc(-15);
			}
			@include breakpoint(medium down) {
				position: relative;
				display: inline-block;
				top: 0;
				left: 0;
				right: 0;
				width: rem-calc(40);
				height: rem-calc(40);
				margin: 0 $global-margin / 2;
			}
			i {
				position: relative;
				z-index: 3;
				color: get-color(black);
				font-size: rem-calc(12);
				transition: $global-transition;
			}
			&:hover,
			&:focus,
			&:active {
				box-shadow: none;
			}
			&:hover {
				transform: scale(0.8);
				background-color: get-color(black);
				i {
					color: get-color(secondary);
				}
			}
		}
		button.owl-next {
			left: auto;
			right: rem-calc(-60);
			@include breakpoint(large down) {
				right: rem-calc(-15);
			}
			i {
				top: rem-calc(-2);
				right: rem-calc(-1);
			}
			@include breakpoint(medium down) {
				position: relative;
				display: inline-block;
				top: 0;
				left: 0;
				right: 0;
			}
		}
		button.owl-prev {
			i {
				top: rem-calc(-2);
				left: rem-calc(-1);
			}
		}
	}
}
.owl-dots {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: $global-margin;
}
.owl-dot {
	display: inline-block;
	position: relative;
	width: rem-calc(25);
	height: rem-calc(25);
	cursor: pointer;
	border: none;
	&:after {
		display: none;
	}
	&:hover,
	&:focus,
	&:active {
		box-shadow: none;
		transform: translate(0);
		&:before {
			transform: translate(-50%, -50%) scale(0.8);
		}
	}
	&.is-active,
	&.active {
		&:before {
			transform: translate(-50%, -50%);
			background-color: get-color(black);
			border-color: get-color(black);
		}
	}
	&:before {
		content: '';
		width: rem-calc(14);
		height: rem-calc(14);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 100%;
		border: rem-calc(3) solid get-color(medium-grey);
		transition: $global-transition;
	}
}

.content-box {
	display: block;
	position: relative;
	padding: $global-padding;
	border: rem-calc(1) solid rgba(get-color(black), 0.1);
	border-radius: $global-radius;
	background-color: get-color(white);
	&-row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		border-bottom: rem-calc(1) solid rgba(get-color(black), 0.1);
		margin-bottom: $global-margin * 3;
		padding-bottom: $global-padding * 3;
		&:before {
			content: normal;
		}
		@include breakpoint(medium down) {
			margin-bottom: $global-margin * 1.5;
			padding-bottom: $global-padding * 1.5;
		}
		&:last-child {
			border: none;
			margin: 0;
			padding: 0;
		}
	}
	&-column {
		flex: 1;
		@include breakpoint(medium down) {
			flex: none;
			width: 100%;
			margin-bottom: $global-margin * 2;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

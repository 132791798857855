// Grid
.blog-item-row {
	display: flex;
	flex-wrap: wrap;
	&:before {
		content: normal;
	}
}
.blog-item-column {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: $global-margin * 1.5;
}
// Product Item
.blog-item {
	display: block;
	position: relative;
	overflow: hidden;
	border: rem-calc(1) solid get-neutral-color(90%);
	background-color: get-color(white);
	padding: $global-padding * 1.5;
	border-radius: $global-radius;
	@include breakpoint(medium down) {
		padding: 0;
	}
	&:hover {
		transform: translatey(-4px);
		border-color: get-color(medium-grey);
	}
	&.-large {
		display: flex;
		align-items: center;
		@include breakpoint(medium down) {
			padding: 0;
			flex-wrap: wrap;
		}
		.blog-item {
			&-image {
				flex: 2;
				margin-right: $global-margin * 6;
				margin-bottom: 0;
				@include breakpoint(medium down) {
					flex: none;
					width: 100%;
					margin: 0;
					margin-bottom: $global-margin;
				}
			}
			&-content {
				flex: 3;
				@include breakpoint(medium down) {
					flex: none;
					width: 100%;
					margin: 0;
					text-align: center;
					padding: 0 $global-padding * 2;
				}
			}
			&-info {
				justify-content: flex-start;
				span {
					margin: 0;
					margin-right: $global-margin * 2;
				}
				@include breakpoint(medium down) {
					justify-content: center;
					span {
						margin: 0 $global-margin;
					}
				}
			}
			&-heading {
				text-align: left;
				@extend h2;
				max-width: rem-calc(400);
				@include breakpoint(medium down) {
					text-align: center;
					margin-left: auto;
					margin-right: auto;
				}
			}
			&-text {
				@extend p;
				max-width: rem-calc(500);
				margin-bottom: 0;
				@include breakpoint(large down) {
					display: none
				}
			}
		}
	}
	&-image {
		display: block;
		position: relative;
		overflow: hidden;
		border-radius: $global-radius;
		margin-bottom: $global-margin;
	}
	&-info {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: $global-weight-book;
		color: get-color(medium-grey);
		letter-spacing: rem-calc(0.5);
		margin-bottom: $global-margin;
		&.-left {
			justify-content: flex-start;
			span {
				margin-left: 0;
				margin-right: $global-margin * 2;
			}
		}
		span {
			margin: 0 $global-margin;
			position: relative;
			&:before {
				content: '';
				width: rem-calc(4);
				height: rem-calc(4);
				position: absolute;
				top: calc(50% - #{rem-calc(2)});
				right: -$global-margin;
				background-color: get-color(medium-grey);
				border-radius: 100%;
			}
			&:last-child {
				&:before {
					display: none;
				}
			}
		}
	}
	&-heading {
		@extend h4;
		max-width: rem-calc(240);
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
		text-align: center;
		@include breakpoint(medium down) {
			margin-bottom: $global-margin;
			max-width: rem-calc(200);
		}
	}
}

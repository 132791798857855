// ===============================
//
// Button
//
// ===============================

	//
	// Base
	//

		.button,
		button,
		[type="submit"] {
			display: inline-block;
			vertical-align: middle;
			line-height: $global-lineheight;
			transition: $global-transition;
			appearance: none;
			border: none;
			outline: none;
			text-decoration: none;
			cursor: pointer;
			border-radius: $global-radius;
			margin: 0;
			padding: $global-padding / 1.5 $global-padding * 1.5;
			text-transform: uppercase;
			letter-spacing: rem-calc(0.5);
			@include breakpoint(medium down) {
				padding: $global-padding / 1.5 $global-padding * 1.2;
			}
		}

	//
	// Colors
	//

		.button,
		button,
		[type="submit"] {
			// Primary
			@include button-color(get-color(primary), get-color(white));
			// Secondary
			&.colour-background-secondary {
				@include button-color(get-color(secondary), get-color(black));
			}
			&.colour-background-white {
				@include button-color(get-color(white), get-color(black));
			}
			// Tertiary
			&.colour-background-tertiary {
				@include button-color(get-color(tertiary), get-color(white));
			}
		}

	//
	// Sizes & Types
	//

		.button,
		button,
		[type="submit"] {
			// Large
			&.size-large {

			}
			// Small
			&.-small {
				font-size: rem-calc(14);
				padding: $global-padding / 2.5 $global-padding;
			}
			// Large
			&.-large {
				font-size: rem-calc(18);
				padding: $global-padding $global-padding * 2.5;
				@include breakpoint(medium down) {
					font-size: rem-calc(16);
					padding: $global-padding $global-padding * 2;
				}
			}
			// Full Width
			&.-full-width {
				width: 100%;
				text-align: center;
			}
			// Disabled
			&.-disabled {
				opacity: 0.4;
				cursor: not-allowed;
			}
			// Text
			&.-text {
				position: relative;
				padding: 0 $global-padding;
				background-color: transparent;
				color: get-color(black);
				font-weight: $global-weight-normal;
				span {
					position: relative;
					display: block;
					transition: $global-transition;
					z-index: 2;
				}
				&:before {
					content: '';
					width: 100%;
					height: rem-calc(6);
					background-color: get-color(secondary);
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 0;
					transition: $global-transition;
					transition-delay: 0.1s;
				}
				&:hover,
				&:focus,
				&:active {
					box-shadow: none;
				}
				&:hover {
					transform: scale(0.95);
					span {
						transform: translatey(-4px)
					}
					&:before {
						transform: scaley(2) scalex(0.9) translatey(-2px);
					}
				}
			}
		}

	//
	// Group
	//

		.button-group {
			.button,
			button,
			[type="submit"] {
				margin-right: $global-margin / 4;
				&:last-child {
					margin-right: 0;
				}
			}
		}

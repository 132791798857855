// Box
.call-to-action {
	display: flex;
	position: relative;
	width: 100%;
	padding: $global-padding;
	border: rem-calc(1) solid rgba(get-color(black), 0.1);
	border-radius: $global-radius;
	background-color: get-color(white);
	transition: $global-transition;
	&:hover {
		transform: translatey(-4px);
		border-color: get-color(medium-grey);
	}
	&-row {
		display: flex;
		flex-wrap: wrap;
		&:before {
			content: normal;
		}
	}
	&-column {
		display: flex;
		flex-wrap: wrap;
		@include breakpoint(small down) {
			margin-bottom: $global-margin;
			&:last-child {
				margin-bottom: 0;
			}
			&:nth-last-child(2) {
			//	margin-bottom: 0;
			}
		}
	}
	&-content {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		background-color: get-color(light-grey);
		border-radius: $global-radius;
		&.-vertical-align-bottom {
			align-items: flex-end;
		}
	}
	&-text {
		width: 50%;
		padding: 0 $global-padding * 2;
		@include breakpoint(medium down) {
			padding: $global-padding $global-padding * 1.5;
		}
		p {
			margin: 0;
		}
	}
	&-image {
		width: 50%;
		@include breakpoint(medium down) {
			max-width: rem-calc(140);
		}
		&.-mockup {
			display: flex;
			align-items: flex-end;
			height: 100%;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: bottom center;
			}
		}
		&.-icon {
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center center;
			}
		}
		&.-lifestyle {
			position: relative;
			height: rem-calc(190);
			overflow: hidden;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: rem-calc(-1);
				width: 20%;
				height: 100%;
				z-index: 3;
				clip-path: polygon(5% 0%, 0% 100%, 100% 100%);
				background-color: get-color(light-grey);
				transform: scale(1.1);
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
			}
		}
	}
}

.accordion {
	display: block;
	position: relative;
	width: 100%;
	&-item {
		display: block;
		width: 100%;
		cursor: pointer;
		padding: $global-padding * 2;
		border-bottom: rem-calc(1) solid get-neutral-color(90%);
		@include breakpoint(medium down) {
			padding: $global-padding * 1.5;
		}
		&:last-child {
			border-bottom: 0;
		}
		&.-active {
			background-color: get-color(x-light-grey);
			margin-top: rem-calc(-1);
			border: rem-calc(1) solid get-neutral-color(90%);
			.accordion-item-heading {
				&:before {
					content: '\f068';
				}
			}
			.accordion-item-content {
				display: block;
				margin-top: $global-padding;
				padding-right: $global-padding * 4;
				max-width: rem-calc(650);
				@include breakpoint(medium down) {
					padding-right: $global-padding;
				}
			}
		}
		&:not(.-active) {
			&:hover {
				.accordion-item {
					&-heading {
						padding-left: $global-padding / 2;
					}
				}
			}
		}
	}
	&-item-heading {
		display: block;
		position: relative;
		@extend h5;
		margin: 0;
		transition: $global-transition;
		padding-right: $global-padding * 3.5;
		@include breakpoint(medium down) {
			padding-right: $global-padding * 2.5;
			font-size: rem-calc(16);
		}
		&:before {
			content: '\f067';
			@extend .fas;
			position: absolute;
			top: calc(50% - #{rem-calc(5)});
			font-size: rem-calc(10);
			right: $global-position / 2;
			@include breakpoint(medium down) {
				right: 0;
			}
		}
	}
	&-item-content {
		display: none;
	}
}

.map {
	display: block;
	position: relative;
	width: 100%;
	height: rem-calc(700);
	background-color: get-color(black);
	border-radius: $global-radius;
	@include breakpoint(medium down) {
		height: rem-calc(300);
	}
	&-info-window {
		display: table;
		margin: auto;
		position: relative;
		background-color: get-color(white);
		border-radius: $global-radius;
		padding: $global-padding / 1.4 $global-padding / 1.2;
		padding-bottom: 0;
		text-align: center;
		transform: translatey(-10px);
		&-heading {
			font-family: $font-family-sans-serif;
			font-size: rem-calc(16);
			color: get-color(black);
			text-transform: uppercase;
			font-weight: $global-weight-bold;
			margin: $global-margin / 5;
		}
		&-text {
			font-family: $font-family-sans-serif;
			font-size: rem-calc(15);
			color: get-color(medium-grey);
			font-weight: $global-weight-book;
			margin: 0;
			text-decoration: underline;
		}
	}
}

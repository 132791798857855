// ===============================
//
// Base
//
// ===============================

	$global-flexbox: false;

	$global-width: rem-calc(1500);

	$global-margin: rem-calc(16);
	$global-padding: rem-calc(16);
	$global-border: rem-calc(3);
	$global-radius: rem-calc(4);

	$global-margin-padding-length: 10;

	$global-position: rem-calc(16);

	$global-easing: get-easing(in-out-circ);
	$global-transition: all 0.2s $global-easing;

// ===============================
//
// Breakpoints
//
// ===============================

	$breakpoints: (
		small: 0,
		medium: 640,
		large: 1024,
		xlarge: 1200,
		xxlarge: 1440
	);

	$breakpoint-classes: (small medium large xlarge);

	$print-breakpoint: large;

// ===============================
//
// Grid
//
// ===============================

	// Float Grid

	$grid-column-gutter: (
		small: 0,
		medium: 20,
		large: 20
	);

	$grid-row-width: $global-width;
	$grid-column-count: 12;
	$grid-column-align-edge: false;
	$grid-row-width: auto;

	// XY Grid

	$xy-grid: false;

	/*
		$grid-margin-gutters: (
			small: 20,
			medium: 30
		);

		$grid-columns: 12;
		$grid-padding-gutters: $grid-margin-gutters;

		$grid-container: $global-width;
		$grid-container-padding: $grid-padding-gutters;
		$grid-container-max: $global-width;

		$xy-block-grid-max: 8;
	*/

// ===============================
//
// Colors
//
// ===============================

	$foundation-palette: (
		// Brand
		primary: #b24230,
		secondary: #ffd51f,
		tertiary: #491f5f,
		// Neutrals
		black: #000,
		white: #FFF,
		neutral: #000,
		x-light-grey: #fafafa,
		light-grey: #f5f5f5,
		medium-grey: #858585,
		body: #606060,
		// Social
		facebook: #3b5998,
		twitter: #55acee,
		instagram: #3f729b,
		linkedin: #0976b4,
		youtube: #e52d27,
		vimeo: #1ab7ea,
		pinterest: #cc2127,
		googleplus: #dd4b39,
		// States
		success: #28a745,
		warning: #ffc107,
		alert: #dc3545
	);

	@include add-foundation-colors;

// ===============================
//
// Typography
//
// ===============================

	// Global

	/*
		112.5% = 18px
		106.3% = 17px
		100% = 	16px
		93.8% = 15px
		87.5% = 14px
	*/

	$global-font-size: 100%;
	$global-font-size-px: 16px;

	$global-lineheight: 1.25;

	$global-weight-normal: 400;
	$global-weight-book: 500;
	$global-weight-bold: 600;

	// Families

	$font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif;
	$font-family-sans-serif: "gnuolane", Helvetica, Arial, sans-serif;
	$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
	$font-family-icon: 'Font Awesome 5 Free';

	$font-path: "../assets/fonts";

	// Body

	$body-font-family: $font-family-sans-serif;
	$body-background: get-color(white);
	$body-font-color: get-neutral-color(40%);

	// Headers

	$header-styles: (
		small: (
			'h1, .h1':(
				'font-size': 22
			),
			'h1.-large':(
				'font-size': 24
			),
			'h2, .h2':(
				'font-size': 20
			),
			'h3, .h3':(
				'font-size': 19
			),
			'h4, .h4':(
				'font-size': 17
			),
			'h5, .h5':(
				'font-size': 17
			),
			'h6, .h6':(
				'font-size': 16
			)
		),
		medium: (
			'h1, .h1':(
				'font-size': 24
			),
			'h1.-large':(
				'font-size': 30
			),
			'h2, .h2':(
				'font-size': 22
			),
			'h3, .h3':(
				'font-size': 19
			),
			'h4, .h4':(
				'font-size': 18
			),
			'h5, .h5':(
				'font-size': 18
			),
			'h6, .h6':(
				'font-size': 16
			)
		),
		large: (
			'h1, .h1':(
				'font-size': 30
			),
			'h1.-large':(
				'font-size': 42
			),
			'h2, .h2':(
				'font-size': 26
			),
			'h3, .h3':(
				'font-size': 21
			),
			'h4, .h4':(
				'font-size': 18
			),
			'h5, .h5':(
				'font-size': 18
			),
			'h6, .h6':(
				'font-size': 16
			)
		),
	);

	$header-font-family: $body-font-family;
	$header-font-weight: $global-weight-bold;

	$header-color: darken($body-font-color, 25%);
	$header-lineheight: $global-lineheight;
	$header-margin-bottom: $global-margin;

	// Paragraphs

	$paragraph-lineheight: $global-lineheight;
	$paragraph-margin-bottom: $global-margin;

	// Selection

	$selection-color: inherit;
	$selection-background-color: rgba(get-color(primary), 0.3);

// ===============================
//
// Links
//
// ===============================

	$anchor-color: get-color(primary);
	$anchor-color-hover: darken(get-color(primary), 15%);
	$anchor-text-decoration: none;
	$anchor-text-decoration-hover: none;

// ===============================
//
// Plugins
//
// ===============================

	// Font Awesome

	$fa-font-path: $font-path;

.lightbox {
	display: block;
	width: 100%;
	margin: auto;
	position: relative;
	max-width: rem-calc(600);
	background-color: get-color(white);
	padding: $global-padding * 4;
	&--flex {
		padding: $global-padding;
		display: flex;
		max-width: rem-calc(960);
		@include breakpoint(small down) {
			flex-wrap: wrap;
		}
	}
	&__text {
		width: 50%;
		display: flex;
		align-items: center;
		padding: $global-padding * 4;
		@include breakpoint(small down) {
			width: 100%;
			padding: $global-padding;
		}
	}
	&__image {
		width: 50%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		@include breakpoint(small down) {
			width: 100%;
			padding-top: 50%;
			margin-top: $global-margin * 2;
		}
	}
}

.mfp-close {
	background-color: transparent !important;
}

.notifications {
	display: block;
	position: relative;
	width: 100%;
	padding: $global-padding;
	text-align: center;
	background-color: get-color(black);
	color: get-color(white);
	margin: 0;
	@extend h5;
}

// Header
.site-header {
	background-color: get-color(light-grey);
	padding-top: $global-padding;
	padding-bottom: $global-padding;
	@include breakpoint(medium down) {
		padding-top: $global-padding / 1.5;
		padding-bottom: $global-padding / 1.5;
	}
	&.-active {
		z-index: 101;
		position: fixed;
		top: 0;
		left: 0;
	}
	&-row {
		@extend .row;
		display: flex;
		justify-content: space-between;
		&:before {
			content: normal;
		}
		&.-align-right {
			justify-content: flex-end;
		}
		@include breakpoint(small down) {
			align-items: center;
		}
	}
	&-column {
		@extend .column;
		flex: 1;
		&:last-child {
			flex: 2;
		}
	}
	&-logo {
		display: inline-block;
		position: relative;
		width: rem-calc(150);
		height: rem-calc(70);
		background-image: url(../assets/images/logo.svg);
		background-size: 80%;
		background-repeat: no-repeat;
		background-position: left center;
		&:hover {
			transform: translatex(3px);
		}
		@include breakpoint(small down) {
			width: rem-calc(120);
			height: rem-calc(50);
		}
	}
	&-dropdown {
		display: flex;
		flex-wrap: wrap;
		position: absolute;
		top: rem-calc(50);
		left: 0;
		z-index: 100;
		background-color: get-neutral-color(10%);
		pointer-events: none;
		width: 100%;
		max-width: rem-calc(1500);
		left: 50%;
		transform: translatex(-50%);
		padding: $global-padding;
		opacity: 0.0;
		transition: $global-transition;
		@include breakpoint(small down) {
			display: none;
		}
	}
	&-dropdown-item {
		width: 20%;
		position: relative;
		@include breakpoint(large down) {
			width: 33%;
		}
		a {
			padding: 0 !important;
			position: relative;
			margin-bottom: $global-margin;
			&:before {
				bottom: rem-calc(-5) !important;
			}
		}
	}
	&-navigation {
		position: relative;
		background-color: get-color(black);
		// padding-top: $global-padding;
		// padding-bottom: $global-padding;
		@include breakpoint(small down) {
			position: fixed;
			top: rem-calc(50);
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 100;
			display: none;
			align-items: center;
			justify-content: center;
			background-color: get-color(light-grey);
			&.-active {
				display: flex;
			}
		}
		ul {
			display: block;
			margin: 0;
			padding: 0;
			text-align: center;
			list-style: none;
		}
		li {
			display: inline-block;
			margin-left: $global-margin / 1.5;
			margin-right: $global-margin / 1.5;
			padding: $global-padding 0;
			@include breakpoint(small down) {
				padding: 0;
			}
			&.is-dropdown {
				&:hover {
					.site-header-dropdown {
						opacity: 1.0;
						pointer-events: all;
					}
				}
			}
			@include breakpoint(medium down) {
				display: none;
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7) {
					display: inline-block;
				}
			}
			@include breakpoint(small down) {
				display: block !important;
				margin-bottom: $global-margin * 1.5;
			}
		}
		a {
			display: inline-block;
			font-size: rem-calc(15);
			text-transform: uppercase;
			color: get-color(white);
			letter-spacing: rem-calc(0.5);
			position: relative;
			@include breakpoint(small down) {
				font-size: rem-calc(16);
				color: get-color(black);
			}
			span {
				display: block;
				position: relative;
				transition: $global-transition;
			}
			&:before {
				content: '';
				width: 100%;
				height: rem-calc(4);
				position: absolute;
				bottom: rem-calc(-21);
				left: 0;
				background-color: get-color(secondary);
				transition: $global-transition;
				transform: scaley(0);
				transition-delay: 0.1s;
				z-index: 2;
				@include breakpoint(small down) {
					bottom: rem-calc(-5);
				}
			}
			&:hover {
				&:before {
					transform: scaley(1);
				}
				span {
					transform: translatey(-3px);
				}
			}
		}
	}
	&-button-group {
		display: flex;
		justify-content: flex-end;
		margin-bottom: $global-margin;
		@include breakpoint(small down) {
			margin: 0;
		}
		.site-header-button {
			margin-left: $global-margin * 2;
			@include breakpoint(small down) {
				margin-left: $global-margin;
			}
		}
	}
	&-button {
		color: get-color(black);
		font-size: rem-calc(13);
		letter-spacing: rem-calc(0.5);
		display: flex;
		align-items: center;
		text-transform: uppercase;
		i {
			position: relative;
			transition: $global-transition;
			margin-right: $global-margin / 2;
		}
		span {
			display: inline-block;
			position: relative;
			transition: $global-transition;
			transition-delay: 0.1s;
		}
		> span {
			@include breakpoint(small down) {
				display: none;
			}
		}
		&.-email {
			font-size: rem-calc(18);
		}
		&.-burger {
			margin-left: $global-margin * 2;
			font-size: rem-calc(18);
			display: none;
			@include breakpoint(small down) {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: $global-margin;
			}
			> i {
				margin: 0;
				width: rem-calc(25);
				height: rem-calc(18);
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.fa-times {
				display: none;
			}
			&.-active {
				.fa-times {
					display: block;
				}
				.fa-bars {
					display: none;
				}
			}
		}
		&-cart-total {
			width: rem-calc(26);
			height: rem-calc(26);
			margin-left: $global-margin / 2;
			border-radius: 100%;
			background-color: get-color(primary);
			color: get-color(white);
			font-size: rem-calc(11);
			display: flex;
			align-items: center;
			justify-content: center;
			transition: $global-transition;
			transition-delay: 0.2s;
			span {
				transform: translatey(-1px);
			}
			@include breakpoint(small down) {
				margin-left: $global-margin / 6;
			}
		}
		&:hover {
			> span {
				transform: translatex(2px);
			}
			i {
				transform: scale(1.2);
			}
			.site-header-button-cart-total {
				transform: translatex(3px);
			}
		}
	}
	&-search {
		color: get-color(black);
		font-size: rem-calc(13);
		letter-spacing: rem-calc(0.5);
		display: flex;
		align-items: center;
		text-transform: uppercase;
		display: none;
		@include breakpoint(small down) {
			display: flex;
		}
		i {
			font-size: rem-calc(16);
			width: rem-calc(16);
			text-align: center;
		}
		.fa-times {
			display: none;
		}
		.fa-search {
			display: block;
		}
		&.-active {
			.fa-times {
				display: block;
			}
			.fa-search {
				display: none;
			}
		}
	}
}

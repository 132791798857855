// ===============================
//
// Lists
//
// ===============================

	//
	// Blank
	//
	
		.list-blank {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		
	//
	// Vertical
	//
	
		.list-vertical {
			li {
				display: inline-block;
				vertical-align: top;
				width: 100%;
				margin-bottom: $global-margin / 3;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	
	//
	// Horizontal
	//
	
		.list-horizontal {
			li {
				display: inline-block;
				vertical-align: middle;
				margin-right: $global-margin / 2;
				&:last-child {
					margin-right: 0;
				}
			}
		}
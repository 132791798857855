.search-bar {
	display: block;
	position: relative;
	overflow: hidden;
	max-height: rem-calc(0);
	opacity: 0.0;
	transition: $global-transition;
	display: none;
	@include breakpoint(small down) {
		display: block;
	}
	&.-active {
		max-height: rem-calc(300);
		opacity: 1.0;
	}
}

.field-search {
	position: relative;
	padding-left: $global-padding;
	display: flex;
	align-items: center;
	@extend .small-padding-left-0x;
	@extend .padding-left-1x;
	@include breakpoint(medium down) {
		flex-direction: column;
		align-items: flex-start;
	}
	label {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		font-family: $font-family-sans-serif;
		font-weight: $global-weight-bold;
		font-size: rem-calc(17);
		color: get-color(primary);
		@extend .margin-right-2x;
		@extend .medium-margin-right-0x;
		@extend .medium-margin-bottom-1x;
		i {
			flex-shrink: 0;
			margin-right: rem-calc(15);
			transform: translatey(-1px);
			@include breakpoint(medium down) {
				transform: translatey(-3px);
			}
		}
		@include breakpoint(medium down) {
			font-size: rem-calc(17);
		}
		@include breakpoint(small down) {
			display: none;
		}
	}
	input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
	select,
	textarea {
		width: 100%;
		border: none;
		border-radius: 0;
		padding: $global-padding / 1.08 $global-padding;
		background-color: get-color(white);
		font-family: $font-family-sans-serif;
		font-weight: $global-weight-bold;
		font-size: rem-calc(20);
		color: get-color(body);
		@include breakpoint(medium down) {
			font-size: rem-calc(16);
		}
	}
	button.button {
		transform: translatex(-1px);
		flex-shrink: 0;
		padding: $global-padding / 1.15 $global-padding * 2.5;
		@extend .colour-background-secondary;
		&:hover {
			background-color: get-color(secondary);
			color: get-color(black);
		}
		@include breakpoint(small down) {
			position: absolute;
			bottom: 0;
			right: 0;
			padding: $global-padding / 1.15 $global-padding * 2;
		}
		@include breakpoint(small down) {
			transform: translatey(-2px);
		}
		&:before {
			display: none;
		}
		&:hover,
		&:focus,
		&:active {
			transform: translatex(-1px) scale(1.0);
			background-color: get-color(primary);
			border-color: get-color(primary);
			@include breakpoint(small down) {
				transform: translatey(-2px)  scale(1.0);
			}
			span {
				transform: translate(0);
			}
		}
	}
}

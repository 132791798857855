// Grid
.logo-item-row {
	display: flex;
	flex-wrap: wrap;
	&:before {
		content: normal;
	}
}
.logo-item-column {
	display: flex;
	flex-wrap: wrap;
	@extend .gutter-bottom-margin;
	@include breakpoint(small down) {
		margin-bottom: $global-margin !important;
		padding: 0 $global-padding / 2;
		&:nth-child(even) {
			padding-right: 0;
		}
		&:nth-child(odd) {
			padding-left: 0;
		}
	}
}
// Product Item
.logo-item {
	display: block;
	position: relative;
	overflow: hidden;
	width: 100%;
	border: rem-calc(1) solid get-neutral-color(90%);
	background-color: get-color(white);
	padding: $global-padding * 2;
	border-radius: $global-radius;
	@include breakpoint(medium down) {
		padding: $global-padding;
	}
	&:hover {
		transform: translatey(-4px);
		border-color: get-color(medium-grey);
	}
	&-image {
		display: block;
		position: relative;
		overflow: hidden;
		img {
			display: block;
			width: 100%;
			max-width: rem-calc(220);
			height: auto;
			margin: auto;
			@include breakpoint(small down) {
				max-width: rem-calc(160);
			}
		}
	}
}

:root {
	// Large
	@include breakpoint(large down) {
		.large {
			&-false {
				display: none;
			}
		}
	}
	@include breakpoint(large only) {
		.large-only {
			&-false {
				display: none;
			}
		}
	}
	// Medium
	.medium {
		&-true {
			display: none;
		}
	}
	@include breakpoint(medium only) {
		.medium-only {
			&-false {
				display: none;
			}
		}
	}
	@include breakpoint(medium down) {
		.medium {
			&-false {
				display: none;
			}
			&-true {
				display: block;
			}
			&-flexbox-wrap {
				flex-wrap: wrap;
			}
			&-order-1 {
				order: 1;
			}
			&-order-2 {
				order: 2;
			}
		}
	}
	// Small
	.small {
		&-true {
			@include breakpoint(medium up) {
				display: none;
			}
		}
	}
	@include breakpoint(small down) {
		.small {
			&-false {
				display: none;
			}
			&-true {
				// display: inline-block;
			}
		}
	}

}

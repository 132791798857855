.product-interior-slide {
	background-color: get-color(x-light-grey);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: $global-radius;
	overflow: hidden;
	width: 100%;
	height: rem-calc(700);
	&:before {
		content: normal;
	}
	@include breakpoint(large down) {
		height: rem-calc(550);
	}
	@include breakpoint(medium down) {
		height: rem-calc(400);
	}
	@include breakpoint(small down) {
		height: rem-calc(300);
	}
	img {
		mix-blend-mode: multiply;
		width: rem-calc(400) !important;
		@include breakpoint(medium down) {
			width: rem-calc(300) !important;
		}
		@include breakpoint(small down) {
			width: rem-calc(200) !important;
		}
	}
	&.-lifestyle {
		background-color: transparent;
		padding: 0;
		img {
			max-width: none;
			width: 100% !important;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
}

.product-interior-slideshow-pagination {
	position: absolute;
	top: $global-position;
	left: $global-position;
	z-index: 10;
	ul {
		display: block;
		padding: 0;
		margin: 0;
	}
	li {
		display: block;
		margin-bottom: $global-margin;
		border: rem-calc(1) solid get-neutral-color(90%);
		background-color: get-color(x-light-grey);
		border-radius: $global-radius;
		padding: $global-padding / 1.5;
		width: rem-calc(60);
		overflow: hidden;
		cursor: pointer;
		transition: $global-transition;
		&:hover {
			border: rem-calc(1) solid get-color(medium-grey);
		}
		&.-lifestyle {
			padding: 0;
			border: 0;
		}
	}
	img {
		mix-blend-mode: multiply;
	}
}
